body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background-color: #efefef;
}

/* shop section */
.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* grid-gap: 2px; */
}
@media screen and (max-width: 900px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.name {
  font-size: clamp(0.7rem, 1vw, 1rem);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: clamp(0.7rem, 1vw, 1rem);
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* resto */

body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: black;
}
i,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0;
  margin: 0;
}
input:focus,
select:focus,
textarea:focus {
  outline: 0px;
}
.row,
.col-6,
.col-4 {
  margin: 0;
  padding: 0;
}
.Announcement {
  background-color: #e11615;
  padding: 3px 0px 3px 0px;
}

.Announcement .col-md-6 p {
  color: white;
  margin-right: 6rem;
  font-size: 15px;
}
.Announcement .col-12 a {
  color: white;
  margin-right: 2rem;
  font-size: 15px;
}
/* Header */
.header {
  padding: 10px 0px 10px 0px;
}
.navbar-brand img {
  width: 60%;
}
.input-group {
  height: 50px;
  width: 90%;
}
.search-button {
  width: 20%;
  color: rgb(0, 0, 0);
  border: 0;
  background-color: #000000;
  text-transform: uppercase;
  font-size: 12px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  font-weight: bold;
  color: white;
}
.Login-Register a {
  text-transform: uppercase;
  font-size: 15px;
  margin-right: 2rem;
  position: relative;
}
.Login-Register a:nth-last-child(1) {
  margin-right: 0rem;
}
.badge {
  width: 20px;
  height: 20px;
  background-color: red;
  position: absolute;
  top: -20px;
  right: -5px;
  /* border-radius: 50%; */
  /* padding: 5px 5px; */
  /* font-size: 12px; */
}
.name-button {
  padding: 5px 15px;
  border: 1px solid #e4e4e4;
  background-color: white;
  border-radius: 2px;
  margin: 0 15px;
}

.dropdown-menu {
  background-color: black !important;
  border: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}
.dropdown-menu a {
  padding: 10px 20px !important;
  color: #ffffff !important;
  margin: 0 !important;
}
.dropdown-menu a:hover {
  background-color: #1cb803 !important;
  color: #ffffff !important;
}
.mobile-header {
  display: none;
}

.menu-item:hover {
  background-color: #1cb803 !important;
  color: white;
}

/* SHOP */
.section {
  width: 100%;
  padding-bottom: 6%;
  padding-top: 2%;
}
.sidebar_item {
  margin-bottom: 35px;
}
.sidebar_item h4 {
  color: #1c1c1c;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;
}
.sidebar_item ul {
  margin: 0;
  padding: 0;
}
.sidebar_item ul li {
  list-style: none;
  display: flex;
}

.sidebar_item ul li a {
  font-size: 16px;
  color: #000000;
  line-height: 45px;
  display: block;
  font-family: "Open Sans", sans-serif;
}
.sidebar_item ul li img {
  width: 25px;
  object-fit: contain;
  margin-left: 20px;
}
.shop {
  margin-bottom: 50px;
}
.border-product {
  padding: 15px;
  border: 1px solid #f3f3f3;
  border-radius: 20px;
}
.shopBack {
  height: 250px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #f3fbf7;
}
.shopBack img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}
.shoptext {
  padding-top: 10px;
}
.shoptext p a {
  color: #252525;
}
.shoptext h3 {
  color: #252525;
  margin-top: 13px;
  font-weight: bold;
  font-size: 19px;
}
.rating {
  margin: 5px 0;
}
.rating i {
  font-size: 12px;
  color: #ebb450;
}
.rating span {
  margin-left: 10px;
}
/* END OF SHOP */

/* NEWSLATTER */
.subscribe-section {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.226), rgba(0, 0, 0, 0.226)),
    url("https://sasshoes-com.s3.us-west-2.amazonaws.com/img/banner-uplift-21.jpg")
      no-repeat scroll center / cover;
  padding: 100px 0 100px;
}
.subscribe-head h2 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0 0 13px;
  text-transform: uppercase;
}
.subscribe-head p {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 39px;
}
.subscribe-head {
  text-align: center;
}
.form-section input[type="email"] {
  border: 0 none;
  border-radius: 25px;
  color: #9b9b9b;
  font-size: 16px;
  font-style: normal;
  height: 51px;
  letter-spacing: 0;
  text-align: center;
  width: 460px;
}
.form-section input[type="submit"] {
  background: #1cb803;
  border: 0 none;
  border-radius: 25px;
  color: #fff;
  height: 52px;
  letter-spacing: 0;
  margin-left: -60px;
  padding: 0 58px;
  text-transform: uppercase;
  font-size: 12px;
}
/* CONTACT */
.contactInfo {
  padding: 70px 0;
}
.contact-Box,
.box-info,
.info-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-info {
  width: 80%;
  flex-direction: column;
  padding: 40px 0;
  box-shadow: 0px 0px 27px 0px rgb(243, 243, 243);
}
.info-image {
  width: 50px;
  height: 50px;
  border: 2px solid #1cb803;
  border-radius: 100px;
  font-size: 20px;
  color: #1cb803;
}
.box-info h5 {
  font-weight: bold;
  font-size: 19px;
  margin-top: 20px;
  margin-bottom: 4px;
}
.box-info p {
  font-size: 16px;
}

/* SINGLE PRODUCT */
.single-product {
  margin-top: 50px;
  margin-bottom: 50px;
}
.single-image {
  background-color: #f3fbf7;
  margin-right: 20px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single-image img {
  width: 90%;
  height: 700px;
  object-fit: contain;
}
.product-name {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 30px;
}

.display-flex {
  display: flex;
}
.product-info {
  width: 100%;
}
.product-dtl p {
  line-height: 24px;
  /* color: #6f0e0e; */
}
.product-count {
  /* border: 2px solid #ab1515; */
  border-radius: 5px;
  margin-top: 40px;
  background-color: #ffffff;
}
.product-count .flex-box {
  padding: 15px 25px;
  border-bottom: 2px solid #dcdcdc;
}
.product-count .flex-box h6 {
  font-size: 16px;
}
.product-count .flex-box span {
  font-weight: 600;
  color: #000000;
}
.product-count .flex-box select {
  background-color: #dcdcdc;
  width: 100px;
  height: 40px;
  text-align: center;
  border: 0px;
  border-radius: 5px;
}
.round-black-btn {
  border-radius: 4px;
  background: #000000;
  color: #fff;
  width: 100%;
  height: 50px;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.5s ease-in-out 0s;
}
.round-black-btn:hover,
.round-black-btn:focus {
  background: #1cb803;
}

/* LOGIN */
.login-center {
  padding: 50px 0;
}
.Login,
.Login2 {
  background: #fff;
  border-radius: 5px;
  position: relative;
  padding: 35px 30px 30px;
  box-shadow: 0 1px 11px rgba(168, 168, 168, 0.27);
  text-align: center;
}
.Login input {
  width: 100%;
  padding: 20px 20px;
  margin-top: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  color: #8a8a8a;
}
.Login input:focus {
  outline: 0px;
}
.Login button,
.Login2 button {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  border: 0;
  background-color: #1cb803;
  border-radius: 5px;
  text-transform: uppercase;
  color: white;
}
.Login button a,
.Login2 button a {
  text-transform: uppercase;
  color: white;
}
.Login p {
  margin-top: 30px;
}
.Login p a {
  color: #7a7a7a;
}
/* PROFILE */
.author-card {
  position: relative;
}
.author-card-cover {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-image: url("https://gust.com/assets/blank_slate/Gust_Profile_CoverPhoto_Blank-21edf1e2890708d5a507204f49afc10b7dc58eb7baea100b68a1bc2c96948297.png");
}
.author-card-profile {
  padding: 20px 10px;
}
.author-card-avatar {
  padding: 0 20px;
}
.author-card-avatar img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 100px;
  margin-top: -60%;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.377);
}
.wizard .nav button {
  padding: 15px 20px;
  font-size: 13px;
  background-color: #ffffff;
  color: black;
  width: 100%;
  text-align: start;
  border-radius: 0;
  text-transform: uppercase;
  border-top: 1px solid #e4e4e4;
}
.wizard .nav .active {
  background-color: #e5fee9;
  color: black;
}
.badge2 {
  width: 20px;
  height: 20px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #ffffff;
}
.form {
  padding: 20px 0;
}
.form label {
  padding: 10px 0;
  color: #8a8a8a;
  text-transform: uppercase;
  font-size: 13px;
}
.form input {
  padding: 20px 10px;
  font-size: 17px;
  background-color: #e5fee9;
}
.form-container button {
  padding: 15px 0;
  border: 0px;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
}
/* orders */
.link {
  color: blue;
  border-bottom: 1px solid blue;
  padding-bottom: 5px;
  font-size: 15px;
}
.link:hover {
  color: blue;
}
.table-responsive {
  width: 100%;
}
/* CART */
.cart-iterm {
  margin: 40px 0;
  padding: 20px;
  background-color: white;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09);
  position: relative;
}

.remove-button {
  background-color: red;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
}
.cart-image img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}
.cart-text a h4,
.cart-price h4 {
  font-size: 17px;
  font-weight: bold;
}
.cart-text p {
  margin-top: 10px;
  font-size: 15px;
  color: #7a7a7a;
}
.cart-qty select {
  width: 100%;
  font-size: 15px;
  padding: 15px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
}
.cart-qty h6,
.cart-price h6 {
  margin-bottom: 15px;
  color: #8c8c8c;
  font-size: 13px;
  text-transform: uppercase;
}
.total {
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: end;
  padding: 0 30px;
}
.total .sub {
  color: #8c8c8c;
  font-size: 20px;
  text-transform: uppercase;
  margin-right: 20px;
}
.total .total-price {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}
.cart-buttons {
  margin: 90px 0;
}
.cart-buttons a button,
.cart-buttons div button {
  width: 80%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
}
.cart-buttons a:nth-child(1) button {
  background-color: #000000;
}
/* FOOTER */
.footer {
  background-color: #ececec;
}
.card-name {
  width: 4%;
  margin: 10px;
}
.card-name img {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

/* Payment */
.payment-container {
  margin-top: 20px;
}
.radio-container {
  padding: 10px 0;
  text-align: start;
}
.radio-container input {
  font-size: 17px;
}
.radio-container label {
  padding: 0 20px;
  font-size: 17px;
}
.order-detail p {
  margin-top: 2px;
}
.order-detail {
  padding: 30px 0;
  background-color: #e5fee9;
}
.order-detail h5 {
  margin-bottom: 6px;
}
.order-box {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.order-product {
  margin: 40px 0;
  border-bottom: 2px solid #f3f3f3;
  position: relative;
}
.order-product h4 {
  font-size: 13px;
  margin-bottom: 10px;
}
.order-product img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
.table-bordered {
  background-color: #f3f3f3;
}
.subtotal-order button {
  width: 100%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
}

/* Pagination */
.pagination .page-link {
  padding: 10px 30px;
  color: black;
}
.page-item.active .page-link {
  background-color: black;
  border: 1px solid black;
}

.carousel-root {
  position: relative;
}

.carousel-root .carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px; /* Ajusta el ancho de la flecha según tus necesidades */
  height: 30px; /* Ajusta la altura de la flecha según tus necesidades */
  background-color: #000; /* Cambia el color de fondo de la flecha */
  color: #fff; /* Cambia el color del texto de la flecha */
  font-size: 18px; /* Ajusta el tamaño de fuente del texto de la flecha */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.carousel-root .carousel-arrow-left {
  left: 10px; /* Ajusta la posición izquierda de la flecha izquierda */
}

.carousel-root .carousel-arrow-right {
  right: 10px; /* Ajusta la posición derecha de la flecha derecha */
}
/* button.rec-dot {
  width: 6px;
  height: 6px;
} */

.whatsapp {
  max-width: 5rem;
  position: fixed;
  bottom: 20px;
  right: 5px;
  /* border: none; */
  /* background: none;   */
  z-index: 5;
}
